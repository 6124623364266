.current-date {
    display: table-cell;
  vertical-align: middle;
}

.current-date auto {
   
}

.big-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: sans-serif;
  font-size: 24px;
}

.day {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
}

.date-details {
  display: flex;
  gap: 5px;
}

.month, .year {
  text-transform: uppercase;
}

.day-of-week {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
