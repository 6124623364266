.prayer-times-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Create 5 equal-width columns */
  grid-gap: 10px; /* Adjust spacing between boxes */
  justify-content: space-around; /* Distribute evenly within the grid */
  align-items: center; /* Center blocks vertically */
  /*height: 100vh;  Occupy full screen height */
  
  flex-wrap: wrap; /* Allow boxes to wrap to multiple rows */
  justify-content: center; /* Center boxes horizontally */
  align-items: center; /* Center boxes vertically */

}

/* Optional: Adjust width for better layout on smaller screens */
@media (max-width: 768px) {
  .prayer-times-list {
    flex-direction: column; /* Switch to vertical layout on narrower screens */
  }
}