.current-time {
    font-size: 3rem;
    /* Adjust font size as needed */
    text-align: center;
    margin-bottom: 20px;
    /* Add spacing if needed */
    background-color: #fff;

    border: 1px solid #ccc;
    border-radius: 5px;
    width: 5em;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
}

.current-time-text>span {
    animation: blink-colon 1s infinite;
}

@keyframes blink-colon {
    50% {
        opacity: 0;
    }
}